<template>
  <div class="wrap-dashboard">
    <div class="main-content">
      <div class="wrap-content">
        <div class="title-page">Detail History</div>
        <div class="text-right">
          <button class="btn btn-scondary mb-3" @click="downloadDeposit">Download</button>
        </div>
        <div ref="downloadmenu" id="downloadMenu">
          <div class="header-content">
            <div>
              <span>HIN</span>
              <span> : </span>
              <span>{{ dataHistory.owner }}</span>
            </div>
            <div>
              <span>Total Item</span>
              <span> : </span>
              <span>{{ dataHistory.total_item }}</span>
            </div>
            <div>
              <span>Weight</span>
              <span> : </span>
              <span>{{ dataHistory.total_weight }}</span>
            </div>
            <div>
              <span>Ref ID</span>
              <span> : </span>
              <span>{{ dataHistory.ref_id }}</span>
            </div>
          </div>
          <div class="content">
            <table>
              <thead>
                <th>No</th>
                <th>Date</th>
                <th>Activity</th>
                <th>Hallmark</th>
                <th>Fineness</th>
                <th>Serial Number</th>
                <th>Quantity</th>
                <th>Weight</th>
                <th>Dimension</th>
              </thead>
              <tbody>
                <tr v-for="(metal, i) in metalInfo" :key="i">
                  <td>{{ i + 1 }}</td>
                  <td>{{ metal.metal_date | formatIso }}</td>
                  <td>{{ metal.activity }}</td>
                  <td>{{ metal.hallmark }}</td>
                  <td>{{ metal.fineness }}</td>
                  <td>{{ metal.serial_number }}</td>
                  <td>{{ metal.quantity }}</td>
                  <td>{{ metal.weight }}</td>
                  <td>{{ metal.dimension }}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";
export default {
  data() {
    return {
      dataHistory: [],
      metalInfo: [],
      toggleDownload: false,
      isHideDeposite: false,
      headerDownload: false,
    };
  },
  mounted() {
    let id = this.$route.params.id;
    axios.get("/v2/clearing/admin/get/detail/history/" + id).then((res) => {
      this.dataHistory = res.data.data.body;
      this.metalInfo = this.dataHistory.metal_info;
    });
  },
  methods: {
    downloadDeposit() {
      // let fileName = "deposit-confirmation.pdf";
      let dataId = document.getElementById("downloadMenu");
      let pdf = new jsPDF({
        orientation: "portrait",
        format: "a4",
      });
      html2canvas(dataId, { useCORS: true, scale: 1 }).then((canvas) => {
        var margin = 8;
        var imgWidth = 210 - 2 * margin;
        var imgHeight = (canvas.height * imgWidth) / canvas.width;
        var pageHeight = 295;
        var heightLeft = imgHeight;
        let img = canvas.toDataURL("image/png", 1);

        var position = 1;
        pdf.addImage(img, "PNG", margin, position, imgWidth, imgHeight);
        heightLeft -= pageHeight;
        while (heightLeft >= 0) {
          position = heightLeft - imgHeight;
          pdf.addPage();
          pdf.addImage(img, "PNG", margin, position, imgWidth, imgHeight);
          heightLeft -= pageHeight;
        }
        pdf.save("withdrawal-confirmation");
      });
    },
  },
};
</script>

<style scoped>
/* .wrap-content {
  width: 98%;
  height: 100%;
  margin: 0 auto;
  padding: 20px 25px;
} */
.title-page {
  font-size: 25px;
  font-weight: bold;
  margin-bottom: 40px;
}

.header-content {
  font-size: 13px;
  margin-bottom: 1rem;
}

.header-content div {
  display: flex;
  gap: 0.5rem;
}

.header-content div span:first-child {
  width: 4rem;
}
</style>
